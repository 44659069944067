<template>
    <v-row no-gutters>
        <v-col cols="12">
            <v-row justify="center" class="py-5 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">Dynamic DNS shared holding account</h1>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5" v-if="isViewReady">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <!-- <p class="text-overline mb-0">Dynamic DNS</p> -->
                    <p class="mb-0 pb-0">
                        <span>Shared domain holding account: {{ dynamicSharedDomainHoldingAccountName }}</span>
                        <v-btn class="blue--text no-print" @click="dialogEditSharedDomainHoldingAccount = true" icon>
                            <font-awesome-icon :icon="['fas', 'pencil-alt']" fixed-width size="1x"/>
                        </v-btn>
                    </p>
                    <v-dialog v-model="dialogEditSharedDomainHoldingAccount" max-width="600">
                        <v-card elevation="4">
                            <v-toolbar flat color="white">
                                <v-toolbar-title>Edit holding account for Dynamic DNS shared domains</v-toolbar-title>
                            </v-toolbar>
                            <v-card-text class="pa-5">
                                <p>There can only be ONE holding account for Dynamic DNS shared domains.</p>
                                <p>Generally this setting should NOT be changed after its initial setting.</p>
                                <v-form @submit.prevent="editDynamicSharedHoldingAccount" @keyup.enter.native="editDynamicSharedHoldingAccount" class="mt-5">
                                    <v-text-field
                                        v-model="editableSharedDomainHoldingAccountId"
                                        ref="editableSharedDomainHoldingAccountIdInput"
                                        label="Account ID"
                                        outlined>
                                    </v-text-field>
                                </v-form>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer/>
                                <v-btn elevation="4" class="purple white--text" @click="editDynamicSharedHoldingAccount" :disabled="!isEditDynamicSharedDomainHoldingAccountFormComplete">
                                    Save
                                </v-btn>
                                <v-btn text class="grey--text" @click="dialogEditSharedDomainHoldingAccount = false">Cancel</v-btn>
                                <v-spacer/>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
export default {
    data: () => ({
        isViewReady: false,

        // generic form controls
        formSubmitTimestamp: null,

        // dynamic dns
        dialogEditSharedDomainHoldingAccount: false,
        dynamicSharedDomainHoldingAccount: null,
        editableSharedDomainHoldingAccountId: null,
    }),
    computed: {
        dynamicSharedDomainHoldingAccountName() {
            return this.dynamicSharedDomainHoldingAccount?.name ?? '';
        },
        isEditDynamicSharedDomainHoldingAccountFormComplete() {
            return typeof this.editableSharedDomainHoldingAccountId === 'string' && this.editableSharedDomainHoldingAccountId !== this.dynamicSharedDomainHoldingAccount?.id;
        },
    },
    watch: {
        dialogEditSharedDomainHoldingAccount(value) {
            if (value) {
                this.editableSharedDomainHoldingAccountId = this.dynamicSharedDomainHoldingAccount?.id;
                this.$nextTick(() => {
                    setTimeout(() => { this.activate('editableSharedDomainHoldingAccountIdInput'); }, 1);
                });
            }
        },
    },
    methods: {
        activate(ref) {
            const inputRef = Array.isArray(this.$refs[ref]) ? this.$refs[ref][0] : this.$refs[ref];
            if (inputRef) {
                // more than one way to do it:
                // 1. inputRef.focus();
                // 2. const inputElement = inputRef.$el.querySelector('input'); inputElement.focus();
                // 3. const inputElement = inputRef.$el.querySelector('input'); document.getElementById(inputElement.id).focus()
                inputRef.focus();
            }
        },
        async editServiceConfig(name, content) {
            try {
                this.$store.commit('loading', { editServiceConfig: true });
                const response = await this.$client.main().system.editSetting({ name, content });
                if (response?.isEdited) {
                    this.$bus.$emit('snackbar', { type: 'success', message: 'Saved changes' });
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', message: 'Failed to save changes' });
                }
                return response?.isEdited ?? false;
            } catch (err) {
                console.error('failed to edit setting', err);
                this.$bus.$emit('snackbar', { type: 'error', message: 'Failed to save changes' });
                return false;
            } finally {
                this.$store.commit('loading', { editServiceConfig: false });
                this.isViewReady = true;
            }
        },
        async editDynamicSharedHoldingAccount() {
            if (Number.isInteger(this.formSubmitTimestamp) && this.formSubmitTimestamp + 500 > Date.now()) {
                return;
            }
            this.formSubmitTimestamp = Date.now();
            const isEdited = await this.editServiceConfig('dynamic_shared_domain_holding_account_id', this.editableSharedDomainHoldingAccountId);
            if (isEdited) {
                this.loadDynamicSharedDomainHoldingAccount(this.editableSharedDomainHoldingAccountId);
            }
            this.dialogEditSharedDomainHoldingAccount = false;
        },
        async loadServiceSettingsList() {
            try {
                this.$store.commit('loading', { loadServiceSettingsList: true });
                const response = await this.$client.main().system.getSettingsList();
                if (response?.list) {
                    const settingsMap = {};
                    response.list.forEach((item) => {
                        settingsMap[item.name] = item.content;
                    });
                    this.registrationMode = settingsMap.registration_mode ?? 'closed';
                    if (settingsMap.dynamic_shared_domain_holding_account_id) {
                        this.loadDynamicSharedDomainHoldingAccount(settingsMap.dynamic_shared_domain_holding_account_id);
                    } else {
                        this.dynamicSharedDomainHoldingAccount = null;
                    }
                }
            } catch (err) {
                console.error('failed to load service settings', err);
            } finally {
                this.$store.commit('loading', { loadServiceSettingsList: false });
                this.isViewReady = true;
            }
        },
        async loadDynamicSharedDomainHoldingAccount(accountId) {
            try {
                this.$store.commit('loading', { loadDynamicSharedDomainHoldingAccount: true });
                this.dynamicSharedDomainHoldingAccount = await this.$client.account(accountId).self.get();
            } catch (err) {
                console.error('failed to load dynamic dns shared domain holding account', err);
            } finally {
                this.$store.commit('loading', { loadDynamicSharedDomainHoldingAccount: false });
            }
        },
    },
    mounted() {
        this.loadServiceSettingsList();
    },
};
</script>
